// export const baseurl = "http://localhost:2145"; //local harsh
// export const baseurl = "http://172.16.6.83:2145"; //local izhar
export const baseurl = "https://node-banksoftware.mobiloitte.io"
// export const baseurl = "https://node.fineximbank.com/"; //live url

const url = `${baseurl}/api/v1`;

const ApiConfig = {
  //ADMIN AUTH
  adminLogin: `${url}/admin/adminLogin`,
  resendOtp: `${url}/user/resendOtp`,
  verifyOtp: `${url}/user/verifyOtp`,
  resetPassword: `${url}/user/resetPassword`,

  //USER MANAGEMENT
  listAllUsers: `${url}/admin/listAllUsers`,


  //TICKET MANAGEMENT
  getAllQueryOrFeedback: `${url}/admin/getAllQueryOrFeedback`,
  resolveQueryOrFeedback: `${url}/admin/resolveQueryOrFeedback`,


}
export default ApiConfig;
